<template>
  <v-container id="add_user" fluid tag="section">
    <v-card active-class="b-radius" elevation="3" style="border-radius: 8px">
      <user-full-form />
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import UserFullForm from "./components/UserFullForm";
import statsActions from "@/calendesk/mixins/statsActions";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "AddUser",
  components: { UserFullForm },
  mixins: [statsActions, planActions],
  data() {
    return {
      statsMode: this.$helpers.statsMode.employees,
    };
  },
  created() {
    this.setSelectedUser(null);
  },
  methods: {
    ...mapMutations({
      setSelectedUser: "user/SET_SELECTED_USER",
    }),
  },
};
</script>
